<template>
  <div>
    <a-page-header title="企业退租" @back="goBack" />
    <div>
      <a-table
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :rowKey="(record, index) => record.id"
        :columns="table.columns"
        :data-source="table.data"
        :pagination="false"
      >
        <div slot="space" slot-scope="text, record">
          {{ text.project_name }}
          <span v-if="text.parent_name_string"
            >/{{ text.parent_name_string }}</span
          >
          <span v-if="text.space_id">/{{ text.space_name }}</span>
        </div>
      </a-table>
    </div>
    <div class="mt20">
      <a-button type="primary" @click="throwLease" style="margin-right: 10px;">退租</a-button>
      <a-button @click="cancel">返回</a-button>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/enterprise/enterprise.js'
import moment from 'moment'
export default {
  data() {
    return {
      selectedRowKeys: [],
      table: {
        columns: [
          {
            title: '入驻空间',
            key: 'space',
            scopedSlots: { customRender: 'space' }
          },
          {
            title: '入驻开始日期',
            dataIndex: 'start_at',
            key: 'start_at',
            customRender: text =>
              text ? moment(text).format('YYYY-MM-DD HH:ss:mm') : ''
          },
          {
            title: '入驻结束日期',
            dataIndex: 'end_at',
            key: 'end_at',
            customRender: text =>
              text ? moment(text).format('YYYY-MM-DD HH:ss:mm') : ''
          },
          {
            title: '企服管家',
            dataIndex: 'user_name',
            key: 'user_name'
          }
        ],
        data: [],
        // 是否有其他空间
        isOtherSpace: false
      }
    }
  },
  mounted() {
    // 获取列表数据
    this.getList()
  },
  created(){
    this.getList()
  },
  methods: {
    // 返回
    goBack() {
      this.$router.go(-1)
    },
    // 获取列表数据
    async getList() {
      try {
        const data = {
          enterprise_id: this.$route.query.enterpriseId,
          page: 1,
          size: 999
        }
        
        const res = await api.throwLeaseList(data)
        console.log(res,'<<<<<<<<<<<<<<<<<<<=====');

        if (res.data.code === '200') {
          this.table.data = res.data.data.list
          this.table.pagination.total = res.data.data.total
          // 清空勾选入驻空间
          this.selectedRowKeys = []
        } else {
          this.$message.error(res.data.msg)
        }
      } catch (error) {}
    },
    // 勾选退租信息
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    },

    // 退租
    async throwLease() {
      const _this = this
      if (_this.selectedRowKeys.length) {
        try {
          const data = {
            settle_id_list: _this.selectedRowKeys
          }
          const res = await api.settleQuitLabel(data)
          if (res.data.code === '200') {
            _this.isOtherSpace = res.data.data
          }
          console.log(_this.isOtherSpace)
          let content = ''
          if (_this.isOtherSpace) {
            content =
              '企业退租后，将于今日解除企业与选定的退租空间的绑定关系，请谨慎操作，如核查无问题，点击确定提交。'
          } else {
            content =
              '企业退租本项目下全部空间，将于实时解除企业与项目绑定关系、解除企服管家与企业的绑定关系、且企业管理员及员工认证状态失效，将无法享受本项目下任何权益，包括项目企服平台管理员权限、通行权限等，请谨慎操作，如核查无问题，点击确定提交。'
          }
          _this.$confirm({
            title: '退租确认',
            content: content,
            okText: '确定',
            cancelText: '取消',
            async onOk() {
              _this.confirmThrowLease()
            },
            onCancel() {
              console.log('Cancel')
            }
          })
        } catch (error) {}
      } else {
        _this.$message.error('请选择退租空间')
      }
    },
    // 确定退租
    async confirmThrowLease() {
      try {
        const data = {
          settle_id_list: this.selectedRowKeys
        }
        const res = await api.throwLease(data)
        if (res.data.code === '200') {
          this.$message.success('操作成功')
          // 清空勾选入驻空间
          this.selectedRowKeys.length = 0
          this.getList()
        } else {
          this.$message.error(res.data.msg)
        }
      } catch (error) {
        console.log(error)
      }
    },
    // 取消
    cancel() {
      this.selectedRowKeys = []
      this.$router.go(-1)
    }
  }
}
</script>

<style lang='scss' scoped>
.mt20{
  margin-top: 20px;
  margin-left: 10px;
}
</style>